/* @component id="spinner-check" version="1.0.0" */
import clsx from "clsx";
import { LoaderCircle, CircleCheckBig } from "lucide-react";

const Icons = {
  spinner: LoaderCircle,
  check: CircleCheckBig,
};

interface SpinnerProps {
  size: string;
  success?: boolean;
}

export default function SpinnerCheck({ size, success = false }: SpinnerProps) {
  const dynamicStyles = {
    width: size,
    height: size,
  };

  return (
    <div
      className={clsx(
        "inline-flex items-center justify-center transition-opacity duration-700 ease-in-out"
      )}
      style={{ width: size, height: size }} // Outer container size
    >
      {success ? (
        <Icons.check
          style={dynamicStyles} // Apply dynamic styles to the icon
          className="text-green-600 transition-opacity duration-700 ease-in-out opacity-100"
        />
      ) : (
        <Icons.spinner
          style={dynamicStyles} // Apply dynamic styles to the icon
          className="animate-spin transition-opacity duration-700 ease-in-out opacity-70"
        />
      )}
    </div>
  );
}
