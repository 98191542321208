/* @component id="auth-button" name="AuthButton" type="ui-auth" version="1.0.0" */
"use client";
import { signIn, signOut } from "next-auth/react";
import { Button } from "../ui/button";
import useAuthorization from "./isAuthorized";
import Link from "next/link";

export default function AuthButton() {
  const { session } = useAuthorization();

  if (session) {
    return (
      <div className="m-auto">
        <Link
          href="https://support.gazl.co/hc/en-us/requests"
          target="_blank"
          className="text-sm mr-3 font-medium hover:underline hover:text-pink-500"
        >
          My Requests
        </Link>
        •
        <Button variant="ghost" onClick={() => signOut()}>
          Sign out
        </Button>
      </div>
    );
  }
  return (
    <>
      <Button className="w-full" onClick={() => signIn("azure-ad")}>
        Sign in
      </Button>
    </>
  );
}
