/*   This is the main landing page
- Users will be greated with a welcome message
- Asked to login if not already
- Guided through selecting a form and completing it
*/
"use client";
import AuthButton from "@/components/Auth/AuthButton";
import Link from "next/link";
import useAuthorization from "@/components/Auth/isAuthorized";
import SpinnerCheck from "@/components/spinner-check";

export default function Home() {
  const { isAuthorized, session, isLoaded, location } = useAuthorization();

  // Not loaded, No Session
  if (!isLoaded && !session)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <SpinnerCheck size={"125px"} />
        <h2 className="my-4 text-2xl font-semibold">Logging in...</h2>
      </div>
    );

  // Loaded, No Session
  if (isLoaded && !session)
    return (
      <div className="flex flex-col justify-center h-full px-6 mb-0 pb-20">
        <div className="h-100 w-100">
          <h1 className="text-4xl font-semibold text-center">Welcome! </h1>
          <h4 className="text-lg font-semibold text-center">
            Please sign in to continue
          </h4>
        </div>
        <div className="w-full flex flex-col items-center">
          <div className="w-1/2">
            <AuthButton />
          </div>
        </div>
      </div>
    );

  // Loaded and session
  if (isLoaded && session)
    return (
      <div className="flex flex-col justify-center h-full px-6 mb-0 pb-0">
        <div className="h-100 w-100">
          <h1 className="text-4xl font-semibold text-center">
            Welcome {session?.user.givenName}!
          </h1>
          <h4 className="text-lg font-semibold text-center">
            What would you like to get started on?
          </h4>
        </div>
        {/* Always show support, show others if authorized */}
        <div
          className={`grid gap-4 p-8 ${
            isAuthorized && location && "md:grid-cols-2"
          }`}
        >
          <Link href="/support">
            <div className="border rounded-xl justify-center text-center w-full py-4 text-md font-normal hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
              <p className="text-lg">New Support Request</p>
              <p className="text-xs pt-2">
                Request help from the Gazelle Support team
              </p>
            </div>
          </Link>
          {/* // Loaded, session, authorized and known location */}
          {isAuthorized && location && (
            <>
              <Link href="/create">
                <div className="border rounded-xl justify-center text-center w-full py-4 text-lg font-normal hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
                  <p className="text-lg">Create User(s)</p>
                  <p className="text-xs pt-2">
                    Request to create a new user(s)
                  </p>
                </div>
              </Link>
              <Link href="/update">
                <div className="border rounded-xl justify-center text-center w-full py-4 text-lg font-normal hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
                  <p className="text-lg">Update User(s)</p>
                  <p className="text-xs pt-2">
                    Request updates to an existing user(s)
                  </p>
                </div>
              </Link>
              <Link href="/disable">
                <div className="border rounded-xl justify-center text-center w-full py-4 text-lg font-normal hover:cursor-pointer hover:bg-gray-100 transform transition duration-200 hover:scale-105">
                  <p className="text-lg">Disable User(s)</p>
                  <p className="text-xs pt-2">
                    Request to disable an existing user(s)
                  </p>
                </div>
              </Link>
            </>
          )}
        </div>
      </div>
    );
}
